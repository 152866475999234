


























import { mapGetters } from "vuex"
import { defineComponent } from "@vue/composition-api"

import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import ReactionUI from "@/components/GroupTeams/Common/Player/Parts/ReactionUI.vue"
import PinUI from "@/components/GroupTeams/Common/Player/Parts/PinUI.vue"
import User from "@shared/User"
import { Role } from "@shared/enums"
import TipJarMixin from "@/mixins/TipJarMixin"

export default defineComponent({
  name: "MeetingUserCard",
  mixins: [TipJarMixin],
  components: {
    GameTipJar: () => import("@/components/GameTipJar.vue"),
    PinUI,
    ReactionUI,
    AppUserCard
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    pinUI: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      Role
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "isModerator"]),
    ...mapGetters("auth", { viewer: "user" }),
    ...mapGetters(["actualGame", "isDIYGame", "gameStatus"]),
    mutable() {
      return (
        this.isHost || User.isPlayer(this.user) === User.isPlayer(this.viewer)
      )
    },
    isHostHidden() {
      return this.actualGame?.isHostHidden
    },
    isPinned() {
      return this.user.id === this.pinedUserID
    },
    pinedUserID() {
      return this.gameStatus.pinedUserID
    },
    isTipJarVisible() {
      return this.$_TipJarMixin_isTipJarVisible && this.user.role === Role.HOST
    }
  }
})
