var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.reactionName || _vm.isRisedHand
    ? _c("div", { staticClass: "reaction-ui" }, [
        _vm.reactionName
          ? _c(
              "div",
              {
                key: _vm.reactionName,
                staticClass: "reaction-ui__animation",
                style: { color: _vm.Reactions[_vm.reactionName].color },
              },
              [
                _c("SvgIcon", {
                  staticClass: "reaction-ui__icon",
                  attrs: { folder: "reactions", name: _vm.reactionName },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.isRisedHand && !_vm.reactionName
          ? _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.lowerHand.apply(null, arguments)
                  },
                },
              },
              [
                _c("v-hover", {
                  staticClass: "reaction-ui__control",
                  attrs: { disabled: !_vm.canLowerHand },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var hover = ref.hover
                          return _c("SvgIcon", {
                            staticClass: "reaction-ui__icon",
                            style: {
                              transform: "scale(-1, 1)",
                              width: "80%",
                              height: "80%",
                            },
                            attrs: {
                              folder: "reactions",
                              name: hover ? "lower-hand" : "raise-hand",
                            },
                          })
                        },
                      },
                    ],
                    null,
                    false,
                    1385571935
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }