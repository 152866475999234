var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pin-ui",
      class: { "pin-ui--pinned": _vm.isPinned },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.$emit("onPin")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "pin-ui__icon" },
        [
          _c("PinIcon", { staticClass: "pin-ui__pin-icon" }),
          _vm.isPinned
            ? _c("PinOffIcon", { staticClass: "pin-ui__pin-off-icon" })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "pin-ui__title" }, [
        _vm._v(" " + _vm._s(_vm.isPinned ? "Unpin" : "Pin") + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }