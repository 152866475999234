
















import { defineComponent } from "@vue/composition-api"

import PinIcon from "@/components/UserCard/icons/PinIcon"
import PinOffIcon from "@/components/UserCard/icons/PinOffIcon"

export default defineComponent({
  name: "PinUI",
  components: {
    PinIcon,
    PinOffIcon
  },
  props: {
    isPinned: {
      type: Boolean,
      default: false
    }
  }
})
