import Vue from "vue"

export default Vue.extend({
  name: "PinIcon",
  functional: true,
  render(h, { data }) {
    return h(
      "svg",
      {
        attrs: {
          viewBox: "0 0 24 24",
          fill: "currentColor"
        },
        ...data
      },
      [
        h("path", {
          attrs: {
            d: "M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z"
          }
        })
      ]
    )
  }
})
