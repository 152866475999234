import { render, staticRenderFns } from "./PinUI.vue?vue&type=template&id=3a240470&"
import script from "./PinUI.vue?vue&type=script&lang=ts&"
export * from "./PinUI.vue?vue&type=script&lang=ts&"
import style0 from "./PinUI.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dkurbatov/Documents/TGG/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a240470')) {
      api.createRecord('3a240470', component.options)
    } else {
      api.reload('3a240470', component.options)
    }
    module.hot.accept("./PinUI.vue?vue&type=template&id=3a240470&", function () {
      api.rerender('3a240470', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/Player/Parts/PinUI.vue"
export default component.exports