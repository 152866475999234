var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meeting-user-card" },
    [
      _c(
        "AppUserCard",
        _vm._g(
          _vm._b(
            {
              staticClass: "meeting-user-card__app-user-card",
              attrs: { user: _vm.user, mutable: _vm.mutable },
            },
            "AppUserCard",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c("ReactionUI", { attrs: { player: _vm.user } }),
          _vm.isTipJarVisible
            ? _c("GameTipJar", {
                attrs: { host: _vm.user, visible: _vm.isHostHidden },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._t("default"),
      _vm.pinUI && (_vm.isHost || _vm.isDIYGame)
        ? _c(
            "div",
            { staticClass: "meeting-user-card__pin-conatiner" },
            [
              _c("PinUI", {
                staticClass: "meeting-user-card__pin-ui",
                attrs: { isPinned: _vm.isPinned },
                on: {
                  onPin: function ($event) {
                    return _vm.$emit("onPin")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }