

































import Vue from "vue"
import { mapGetters } from "vuex"
import { db } from "@/firebase"
import { Reactions } from "@/components/GroupTeams/Common/Reactions/reactions.js"
export default Vue.extend({
  name: "ReactionUI",
  props: {
    player: {
      type: Object,
      required: true
    }
  },
  data() {
    return { Reactions, reactionName: null, reactionTimeoutID: null }
  },
  computed: {
    ...mapGetters("auth", ["user", "isHost", "isModerator"]),
    ...mapGetters(["gameStatus"]),
    ...mapGetters({
      currentMissionID: "currentMission",
      selectedUserID: "getMeetingModeActiveUser"
    }),
    screensharedID() {
      return this.gameStatus?.shareRef?.screenShareBy
    },
    reaction() {
      return this.player?.reaction
    },
    isRisedHand() {
      return this.player?.raisehand?.missionID === this.currentMissionID
    },
    isCurrentUser() {
      return this.player.id === this.user.id
    },
    isPresenter() {
      return [this.selectedUserID, this.screensharedID].includes(this.user.id)
    },
    canLowerHand() {
      return (
        this.isCurrentUser ||
        this.isHost ||
        this.isModerator ||
        this.isPresenter
      )
    }
  },
  watch: {
    reaction(nextValue, prevValue) {
      const isChanged = nextValue?.timestamp !== prevValue?.timestamp
      if (
        isChanged &&
        nextValue?.timestamp + 8000 > Date.now() &&
        nextValue?.name
      ) {
        this.reactionName = nextValue?.name
        clearTimeout(this.reactionTimeoutID)
        this.reactionTimeoutID = setTimeout(() => {
          this.reactionName = null
        }, 8000)
      }
    },
    isRisedHand(value) {
      if (value) {
        clearTimeout(this.reactionTimeoutID)
        this.reactionName = null
      }
    }
  },
  methods: {
    async lowerHand() {
      if (this.canLowerHand) {
        db.auxiliary().ref(`org/1/users/${this.player.id}/raisehand`).set(null)
      }
    }
  }
})
